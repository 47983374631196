import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import "../Pagina.css";
import "../content-styles.css"

import * as Hosts from '../../../Hosts';
import {
	reqGET,
    set_language,
    content_language,
    repo_site_assets_link

} from '../../../Utils';

import './Tarifas.css';

import Loader from '../../../loader';
import Navbar from "../Navbar/Navbar";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Footer from "../Footer/Footer";

import { language } from '../WEBContent/Language';

const Tarifas = () => {

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [, set_lang_id] = useState('1');

    const [store_state, set_store_state] = useState(true);

    useEffect(() => { 

        let getInfoResort = async () => {
            //setLoading(true)
            await reqGET(`${Hosts.SIMCore_API}/estancia/config/${Hosts.companycode}/1`)
              .then(res => {
                set_store_state(res.data[0].info.store.state)
  
              })
              .catch(erro => console.log('Erro', erro))
        }
        getInfoResort()

        if(localStorage.getItem('lang') === undefined  || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if(el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }

        setLoading(false)
    
    }, []); 

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar /> 

            <Breadcrumb /> 

            <div id="tarifas" className="container-fluid my-5">
                <div className="row">
                    <div className="container mb-5 d-none">
                        <h3 className="titulo-primario">{language[lang].store_tarifas}</h3>
                    </div>

                    {
                    store_state ?
                        <div className="container">
                            <div className="row">

                                <div className="col-12 col-md-10 offset-md-1">
                                    <div className='row'>

                                        <div className='col-12 mb-4'>
                                            <h5 className='titulo-secundario'>
                                                {language[lang].store_tarifas_info}
                                            </h5>
                                        </div>


                                        <div className="col-12 col-md-6 card border-0">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("programa1.jpg") + `)` }} title="Forfaits" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">{language[lang].store_forfaits}</h4>
                                                    <p className="card-text">{language[lang].store_forfaits_txt}</p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/forfaits' }} replace>
                                                    {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 card border-0 mt-5 mt-md-0">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("programa4.jpg") + `)` }} title="Equipamentos" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">{language[lang].store_equipamentos}</h4>
                                                    <p className="card-text">{language[lang].store_equipamentos_txt}</p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/equipments' }} replace>
                                                    {language[lang].alugar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 card border-0 mt-5">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("programa3.jpg") + `)` }} title="Aulas" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">{language[lang].store_aulas}</h4>
                                                    <p className="card-text">{language[lang].store_aulas_txt}</p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/aulas' }} replace>
                                                    {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 card border-0 mt-5 d-none">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("programa2.jpg") + `)` }} title="Programas" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">{language[lang].store_atividades}</h4>
                                                    <p className="card-text"></p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/activities' }} replace>
                                                    {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 card border-0 mt-5 d-none">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("packs.jpg") + `)` }} title="Programas" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">{language[lang].store_packs}</h4>
                                                    <p className="card-text"></p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/packs' }} replace>
                                                    {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 card border-0 mt-5 d-none">
                                            <div className="img-fluid card-img-top background-image" style={{ backgroundImage: `url(` + repo_site_assets_link("vouchers.jpg") + `)` }} title="Programas" ></div>
                                            <div className="card-body p-3 bg-color-gray-light">
                                                <div className="info mb-4">

                                                    <h4 className="card-title product-name">Vouchers</h4>
                                                    <p className="card-text">O forfait é um cartão, de aquisição obrigatória para qualquer praticante de ski ou snowboard, que permite oacesso às pistas, meios mecânicos e seguro em caso de acidente.</p>
                                                    
                                                </div>
                                                <Link className="btn btn-primary float-right" to={{ pathname: '/store/vouchers' }} replace>
                                                    {language[lang].comprar} <i className="bi bi-arrow-right ml-2 p-2"></i>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> 
                    :
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 text-center'>
                                    <i class="bi bi-clock-history" style={{fontSize: '150px'}}></i>
                                </div>
                                <div className='col-12 my-5 text-center'>
                                    <h3>{language[lang].loja_fechada}</h3>
                                </div>
                            </div>
                        </div>
                    }
                </div> 
            </div>    
        
            <Footer />
        </>
      
    );

};
export default Tarifas;

